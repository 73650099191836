export const environment = {
  "apiUrl": "https://adminapi.starkcore.com/api/v1/",
  "apiUrlStarkcore": "https://api.starkcore.com/",
  "auth0Domain": "https://digimark2016.auth0.com/dbconnections/change_password",
  "auth0Id": "ZNCcPMMNeWWaXkqJbh1AabByRaS8SP4g",
  "env": "prod",
  "hmr": false,
  "logrocketKey": "pkq3s1/admin-module-starkcore-v1",
  "production": true,
  "pusher_apiKey": "a3f645859e02fcb5e882",
  "pusher_cluster": "us2"
};
