import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  account: string;
  accountData: any = null;

  constructor(private http: HttpService) {}

  async getInitUserInfo(): Promise<Observable<any>> {
    if (this.accountData) {
      return this.accountData;
    } else {
      try {
        const response = await this.http.postStarkCore<any>('api/getAllUserData', null).toPromise();
        this.accountData = response.accountData;
        return this.accountData; // Resolved data
      } catch (error) {
        console.error('Error fetching company data:', error);
        throw error; // Handle or rethrow error
      }
    }
  }

  getUserInfo(): Observable<any> {
    const resource = 'api/getAllUserData';
    return this.http.postStarkCore<any>(resource, null);
  }
}
